$spacer: 1em;

$gutter-min: 1px;
$gutter-xs: $spacer / 4;
$gutter-sm: $spacer / 2;
$gutter-base: $spacer;
$gutter-md: $spacer * 1.5;
$gutter-lg: $spacer * 3;
$gutter-xl: $spacer * 6;
$gutter-xxl: $spacer * 12;
$gutter-auto: auto;
