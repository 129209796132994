@import "../../../assets/css/variables/variables";

#adventure-final-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  z-index: 100;
}
.tool-treasure {}
.tool-treasure-container {
  position: relative;
  flex: auto;
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 $gutter-md;

  @media (min-width: 1440px) {
    height: 100%;
    top: 0;
    left: 0;
    right: auto;
  }
  & > div {
    width: 100%;
  }
  .iframe {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
  }

}

.tool-treasure-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 0 10px;
}

.tool-treasure-titles {
  padding: $gutter-md 0;
  text-align:center;
  h1 {
    margin: 0;
  }
  h3 {
    margin-top: 0;
  }
}
.tool-treasure-email-form {
  margin-top: $gutter-lg;
  padding-bottom: $gutter-md;
  padding-left: $gutter-sm;
  padding-right: $gutter-sm;
  h3 {
    text-align: center;
  }
}