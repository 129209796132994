.screen-tool {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  overflow: auto;
  position: relative;

  &.tool-coder {
    //border: 20px solid #d2d2d8;
  }

  &.fullScreen {
    margin-top: 0;
  }

  @media(min-height: 700px) {
    //justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.tool-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 20px 10px 20px;
  background-size: cover;
  border-radius: 0;
  margin: 0 auto;
  .frame-14769 & {
    //overflow-y: hidden;
    overflow-x: auto;
  }
  & > div:first-child {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.hasIcon {
    padding-top: 30px;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }


  &.preview {
    min-height: 0;
  }
  &.fullScreen {
    padding: 0;
    border-radius: 0;
  }
}

.screen-tool-buttons-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.screen-tool-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  button {
    //width: 200px;
    margin: 0 5px 15px;
  }
}

.tool-text-after {
  padding: 10px 0;
  p {
    margin-top:0;
    margin-bottom:0
  }
}