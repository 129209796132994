.switches-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .frame-14769 & {
    flex-wrap: nowrap;
    min-width: 30rem;
    .switch-single-title {
      font-size: 1.3rem;
      @media (min-width:640px) {
        font-size: 1.7rem;
        //min-width: 43rem;
      }
    }
    .switch-single-image {
     max-width: 30px;
    }
  }
  &.row {
    flex-direction: row;
    justify-content: space-evenly;
    .frame-14769 & {
      //justify-content: flex-start;
    }
  }
  &.column {
    flex-direction: column;
  }
}