@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@-webkit-keyframes scale-close {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes scale-close {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@-webkit-keyframes slide-t {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-t {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-t-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slide-t-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@-webkit-keyframes slide-r {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-r {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-r-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slide-r-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-b {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-b {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-b-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slide-b-close {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@-webkit-keyframes slide-l {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-l {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-l-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slide-l-close {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slider {
  width: 100%;
  height: 100%;
}

.slider_wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  &.indicators-up {
   flex-direction: column-reverse;
  }
}

.slider_content {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider_item {
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: center;
  transition: opacity 1s;
  right: 0;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.slider_item-active {
  position: relative;
  display: block;
  right: 0;
  opacity: 1;
  z-index: 1;
}

.slider_arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  width: 100px;
  transition: opacity .2s;
  opacity: 0;
}

.slider_arrow-left {
  left: 0;
}

.slider_arrow-right {
  right: 0;
}

.slider_indicators {
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider_indicators-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex: 1;
  .adventure-type-362 & {
    flex-wrap: wrap;
  }
}

.slider_indicators-next, .slider_indicators-prev {
  cursor: pointer;
}

.slider_indicators-next {
  transform: rotate(180deg);
}

.slider_indicators ul {
  display: flex;
  justify-content: center;
  padding: 0;
}

.slider_indicators_item {
  //width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;
  margin: 0 5px;
  list-style: none;
  cursor: pointer;
  transition: all .3s;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  .adventure-type-324 &, .adventure-type-332 &, .adventure-type-330 & {
    width: auto;
    height: auto;
    background-color: transparent;
    border: 0;
    &.active {
      background-color: transparent;
      border: 0;
    }
  }
  .adventure-type-362 & {
    width: 30px;
    margin-bottom: 5px;
  }
  .adventure-type-311 & {

    background-color: transparent;
    border: 0;
    width: 30px;
    &.active {
      border: 0;
      background-color: transparent;
    }
  }
  &.active {
    background: #000000;
    color: #ffffff;
    transform: scale(1.1);
    .adventure-type-324.screen-tool-62 &,
    .adventure-type-330.screen-tool-62 &,
    .adventure-type-332.screen-tool-62 & {
      color: #000;
    }

  }
}

.slider_indicators-prev {

}

.slider_indicators-next {

}

.slider:hover .slider_arrow {
  opacity: 1;
}

.debug .slider_wrapper {
  border: 1px solid red;
}

.debug .slider_content {
  border: 1px solid blue;
}

.debug .slider_item {
  border: 1px solid #000;
}
