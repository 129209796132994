* {
    /*font-family: 'Assistant', sans-serif;*/
    box-sizing: border-box;
}

html {
    font-size: 62.5%
}

@font-face {
    font-family: "Fedra Sans Hebrew Book";
    src: url('./assets/fonts/FedraSansH+L-Book.otf');
}

@font-face {
    font-family: "Fedra Sans Hebrew Medium";
    src: url('./assets/fonts/FedraSansH+L-Medium.otf');
}

* {
    font-family: 'Fedra Sans Hebrew Book';
}
h1, h2, h3,h4,h5,h6 {
    font-family: 'Fedra Sans Hebrew Medium';
}
html,
body {
    padding: 0;
    margin: 0;
}

.ytp-title-text {
    display: none !important;
}

.iframe body {
    background-color: transparent;
}