.switch-single-general-switch{
  object-fit: contain;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
  &.checked {
    align-items: flex-start;
  }
}

.switch-single-general-button{
  box-shadow: inset 0px -4px 5px 0px black, 0px 1px 5px 0px black;
  -webkit-tap-highlight-color: transparent;
  // margin: -15px;
  border-radius: 50%;
  cursor: pointer;
  &:hover{
    filter: brightness(85%);
  }
}

.switch-single-container{
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  margin: 5px;
  .frame-14769 & {
    margin: 0;
    @media (min-width:640px) {
      margin: 5px;
    }
  }

  .switch-single-title{
    margin: 15px 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    user-select: none;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .switch-single-image-wrapper{
    @extend .switch-single-general-switch;
    .switch-single-image-button{
      @extend .switch-single-general-button;
    }
  }

  .switch-single-image{
    @extend .switch-single-general-switch;
    &:hover{
      cursor: pointer;
    }
  }

  .switch-single-wrapper{
    @extend .switch-single-general-switch;
    box-shadow: inset 0px 0px 10px 0px black;
    padding: 2.5px;
    border-radius: 50px;
    .switch-single-button {
      @extend .switch-single-general-button;
    }
  }
}


